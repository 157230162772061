// require('./bootstrap-custom')
require('./select2-custom')
// require('./international-telephone-input-custom')
require('./bootstrap-datepicker-custom')
require('./numeral-js')
require('./print-this')
require('./js-barcode')
// require('./owl-carousel-custom')
// require('./jquery-slidePanel')
// require('./jquery-lazy-custom')
// require('./jquery-mask-custom')
// require('./magnific-popup-custom')
// require('./jquery-googlemap-custom')
// require('./bootstrap-star-rating-custom')
// require('./jquery-geocomplete-custom')
// require('./jquery-dropify-custom')
// require('./jquery-datatables-custom')
// require('./jquery-sweet-alert-custom')
// require('./js-cookie-custom')
// require('./jquery-print-custom')
//require('./jquery.maskMoney')
