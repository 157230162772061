export default function companyInformation() {

  var Site = global.Site || {};

  (function(app) {
      'use strict';
  
      app.pages.companyInformation = {
        modalContentType: null,
      };
  
      var _this = app.pages.companyInformation;
  
      _this.init = function() {

        // var shippingTemplateTypeSelected = $("input[type=radio][name=companyType]:checked").val();
        // _this.companyTypeChange(shippingTemplateTypeSelected)

        // $(document).on('change', 'input[type=radio][name=companyType]', function(){
        //     _this.companyTypeChange($(this).val());
        // });

        // $(document).on('change', 'input[type=file][id=companyLogo]', function(){
        //     alert("Şimdi Yükle");
        // });

        $('#modalInformationEdit').on('shown.bs.modal', function () {
          if(_this.modalContentType == 1)
          {
            $("#modalInformationEdit .nk-nav.nav.nav-tabs .nav-item").eq(0).find('a').trigger("click")
          }
          else if (_this.modalContentType == 2) 
          {
            $("#modalInformationEdit .nk-nav.nav.nav-tabs .nav-item").eq(1).find('a').trigger("click")
          }
        });

      }

      // _this.companyTypeChange = function (value) {
      //   if(value != undefined)
      //   {   
      //       if(value == "1")
      //       {
      //           $("#companyTaxDepartmentWrapper").show();
      //       }
      //       else if (value == "2")
      //       {
      //           $("#companyTaxDepartmentWrapper").hide();
      //       }
      //   }
      // }
  
  })(Site);

  global.Site = $.extend(global.Site, Site);

}