export default function shippingTemplate() {

  var Site = global.Site || {};

  (function(app) {
      'use strict';
  
      app.pages.shippingTemplate = {};
  
      var _this = app.pages.shippingTemplate;
  
      _this.init = function() {

        var shippingTemplateTypeSelected = $("input[type=radio][name=shippingTemplateType]:checked").val();
        _this.templateChange(shippingTemplateTypeSelected)

        $(document).on('change', 'input[type=radio][name=shippingTemplateType]', function(){
            _this.templateChange($(this).val());
        });

        _this.isShowElement();

        $(document).on('change', '#templateContentInner input[type=checkbox]', function(){
            _this.isShowElement();
            // if ($(this).prop("checked")) {
            //     $(this).val(true);
            // } else {
            //     $(this).val(false);
            // }
        });
        
      }

      _this.templateChange = function (value) {
        if(value != undefined)
        {   
            if(value == "a4")
            {
                $("#ShippingTemplatePrint").addClass("a4").removeClass("a5").removeClass("thermal-printer");
                $("#information-wrapper").removeClass("flex-column").removeClass("flex-column-reverse").removeClass("text-center");
                $("#borcode-wrapper").removeClass("mb-2").addClass("ml-4").removeClass("w-100");

            }
            else if (value == "a5")
            {
                $("#ShippingTemplatePrint").addClass("a5").removeClass("a4").removeClass("thermal-printer");
                $("#information-wrapper").addClass("flex-column").addClass("flex-column-reverse").addClass("text-center");
                $("#borcode-wrapper").addClass("mb-2").removeClass("ml-4").addClass("w-100");
            }
            else if (value == "thermalPrinter")
            {
                $("#ShippingTemplatePrint").addClass("thermal-printer").removeClass("a4").removeClass("a5");
            }
        }
      }

      _this.isShowElement = function () {
        $('#templateContentInner .form-group').each(function(index, el) {
          var isChecked = $(el).find("input[type=checkbox]").is(":checked");
          var elementId = $(el).find("input[type=checkbox]").attr("data-for");
          if (isChecked) 
          {
            $("#ShippingTemplatePrint").find('#' + elementId).show();
            $("#ShippingTemplatePrint").find('.' + elementId).show();
            $(el).find("input[type=checkbox]").attr('value', true);
          }
          else
          {
            $("#ShippingTemplatePrint").find('#' + elementId).hide();
            $("#ShippingTemplatePrint").find('.' + elementId).hide();
            $(el).find("input[type=checkbox]").attr('value', false);
          }
        });

        if($("#templateContent7").is(":checked") == false && $("#templateContent10").is(":checked") == false) 
        {
          $("#borcode-wrapper").hide();
        }
        else
        {
          $("#borcode-wrapper").show();
        }

      }
  
  })(Site);

  global.Site = $.extend(global.Site, Site);

}