export default function loading() {

    var Site = global.Site || {};

    (function(app) {
        'use strict';

        app.helpers.loading = {};

        var loadingHtml =   '<div id="loadingWrapper" class="loading-wrapper w-100 h-100 position-fixed" style="z-index:9999; background:rgba(235,238,242,0.5)">' +
                            '<div class="d-flex justify-align-center h-100 align-items-center">' +
                            '<div class="spinner-border ml-auto mr-auto" role="status" aria-hidden="true"></div>' +
                            '</div>' +
                            '</div>';

        var _this = {

            start: function() {
                $("body").prepend(loadingHtml);
            },

            stop: function() {
                $("#loadingWrapper").remove();
            },

        };

        app.helpers.loading = _this;     

    })(Site);

    global.Site = $.extend(global.Site, Site);

}