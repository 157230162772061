export default function newInvoice() {

  var Site = global.Site || {};

  (function(app) {
      'use strict';
  
      app.pages.newInvoice = {
      };
  
      var _this = app.pages.newInvoice;
  
      _this.init = function() {

        $("#customerLookup").select2({
          language: "tr",
          width: '100%',
          dropdownAutoWidth : true,
          allowClear: true,
          minimumInputLength: 3,
          minimumResultsForSearch: Infinity,
          tags: true,
          theme: "default select2-lg",
          ajax: {
            url: "/musteri-sorgula",
            dataType: 'json',
            delay: 250,
            type: 'GET',
            data: function (params) {
                return {
                    q: params.term, // search term
                };
            },
            processResults: function (data) {
                var arr = []
                if(data.is_success)
                {
                  $.each(data.datas, function (index, value) {

                      if(value.customer_type == 1) 
                      {
                        arr.push({
                          id: value.id + "_" + value.e_invoice,
                          text: value.name + " " + value.surname + " - " + value.identifier,
                        })
                      }
                      else
                      {
                        arr.push({
                          id: value.id + "_" + value.e_invoice,
                          text: value.company_name + " - " + value.tax_no,
                        })
                      }

                  })
                }
                return {
                    results: arr
                };
            },
            cache: true
          },
          insertTag: function (data, tag) {
            if(data.length == 0)
            {
              data.push({
                id: "-1",
                text: "+ Yeni Müşteri Ekle",
                newTag: true,
              });
            }
          },
        });

        $(document).on('select2:select','#customerLookup', function (e) {

            if(e.params.data.id == "-1") {
              $("#modalAddCustomer").modal();
            }

            var id = e.params.data.id.split("_");
            if(id[1] == "false")
            {
              $('#invoiceType').prop('disabled', true);
              $('#invoiceType').val("4").trigger('change');
              $('#invoiceType option[value=4]').prop('disabled', false);
            }
            else
            {
              $('#invoiceType').val("0").trigger('change');
              $('#invoiceType').prop('disabled', false);
              $('#invoiceType option[value=4]').prop('disabled', true);
            }
        });

        $(document).on('select2:clear','#customerLookup', function (e) {
          $('#invoiceType').prop('disabled', false);
          $('#invoiceType').val("0").trigger('change');
        });

        $("#btnGercek").trigger("click");
        
        _this.newItemRow();

        var currencySymbol = $('#CurrencyUnit').find(':selected').data('symbol');
        if(currencySymbol != null && currencySymbol != undefined && currencySymbol.length > 0) {
          _this.currencyWrite(currencySymbol);
        }

        $(document).on('select2:select','#CurrencyUnit', function (e) {
            _this.currencyWrite($('#CurrencyUnit').find(':selected').data('symbol'));
        });

        if($(".new-invoice").length > 0) {
          $("div[data-content=sidebarMenu]").addClass("is-compact");
          $("a[data-target=sidebarMenu]").addClass("compact-active");
        }

        // load a locale
        Numeral.register('locale', 'tr', {
            delimiters: {
                thousands: '.',
                decimal: ','
            },
            abbreviations: {
                thousand: 'bin',
                million: 'milyon',
                billion: 'milyar',
                trillion: 'trilyon'
            },
            ordinal : function (number) {
                return number === 1 ? 'er' : 'ème';
            },
            currency: {
                symbol: '₺'
            }
        });

        // switch between locales
        Numeral.locale('tr');

        $(document).on('blur','.n-format-item-amount', function () {            
            _this.calculateItem($(this).parents(".item-record"));
        });

        $(document).on('blur','.n-format-item-unit-price', function () {            
            _this.calculateItem($(this).parents(".item-record"));
        });

        $(document).on('blur','.n-format-item-discount-rate', function () {            
            //_this.calculateItem($(this).parents(".item-record"));
        });

        $(document).on('blur','.n-format-item-discount-amount', function () {            
            _this.calculateItem($(this).parents(".item-record"), "discountRate");
        });

        $(document).on('blur','.n-format-item-vat-rate', function () {            
            _this.calculateItem($(this).parents(".item-record"), "vatRate");
        });

        _this.invoiceSummary();

        $(document).on('click','#invoiceSave', function (e) {
          e.preventDefault();
          _this.itemListJsonCreate();
        });
        

      }

      _this.newItemRow = function() {
        $("select.form-select").select2("destroy");
        $("#itemContent").prepend($("#newItemClone").clone().show().removeAttr("id"))[0];
        NioApp.Select2.init();
          $('.n-format-item-amount').inputmask('currency', {alias: 'numeric', radixPoint:',', prefix:'', groupSeparator: '.', digitsOptional: false, rightAlign: false, digits: 6, placeholder: "0" });
          $('.n-format-item-unit-price').inputmask('currency', {alias: 'numeric', radixPoint:',', prefix:'', groupSeparator: '.', digitsOptional: false, rightAlign: false, digits: 6, placeholder: "0" });
          $('.n-format-item-discount-rate').inputmask('currency', {alias: 'numeric', radixPoint:',', prefix:'', groupSeparator: '.', digitsOptional: false, rightAlign: false, digits: 2, placeholder: "0" });
          $('.n-format-item-discount-amount').inputmask('currency', {alias: 'numeric', radixPoint:',', prefix:'', groupSeparator: '.', digitsOptional: false, rightAlign: false, digits: 2, placeholder: "0" });
          $('.n-format-item-vat-rate').inputmask('currency', {alias: 'numeric', radixPoint:',', prefix:'', groupSeparator: '.', digitsOptional: false, rightAlign: false, digits: 2, placeholder: "0" });
          $('.n-format-item-vat-amount').inputmask('currency', {alias: 'numeric', radixPoint:',', prefix:'', groupSeparator: '.', digitsOptional: false, rightAlign: false, digits: 2, placeholder: "0" });
          $('.n-format-item-total-amount').inputmask('currency', {alias: 'numeric', radixPoint:',', prefix:'', groupSeparator: '.', digitsOptional: false, rightAlign: false, digits: 2, placeholder: "0" });
      }

      _this.itemRemove = function(element) {
        var itemsCount = $("#itemContent").find("tr.item-record").length;
        if(itemsCount > 2)
        {
          $(element).parents("tr.item-record").remove();
          _this.invoiceSummary();
        }
      }

      _this.currencyWrite = function(symbol) {
        $("span[data-current-currency]").html(symbol);
      }

      _this.convertDecimal = function (stringValue) {
        stringValue = stringValue.trim();
        var result = stringValue.replace(/[^0-9]/g, '');
        if (/[,\.]\d{2}$/.test(stringValue)) {
            result = result.replace(/(\d{2})$/, '.$1');
        }
        return parseFloat(result);
      }

      _this.calculateItem = function(element, discountType) {
        var amount = parseFloat($(element).find(".n-format-item-amount").val().replace('.',"").replace(",","."));
        var unitPrice = parseFloat($(element).find(".n-format-item-unit-price").val().replace('.',"").replace(",","."));
        var discountRate = parseFloat($(element).find(".n-format-item-discount-rate").val().replace(",","."));
        var discountAmount = _this.convertDecimal($(element).find(".n-format-item-discount-amount").val());
        var vatRate = parseFloat($(element).find(".n-format-item-vat-rate").val().replace('.',"").replace(",","."));
        var vatAmount = 0;
        var totalAmount = 0;
        if(discountType == "discountRate")
        {
            if(vatRate == 0) {
              totalAmount = parseFloat((amount * unitPrice) - discountAmount);
            }
            else
            {
              totalAmount = parseFloat((amount * unitPrice) - discountAmount);
              vatAmount = parseFloat(((amount * unitPrice) - discountAmount) * (vatRate / 100));
            }
            var newDiscountRate = parseFloat((100 * discountAmount) / (amount * unitPrice));
            $(element).find(".n-format-item-discount-rate").val(newDiscountRate.toFixed(2));

        }
        else
        {

          if(vatRate == 0) {
            totalAmount = parseFloat((amount * unitPrice) - (amount * unitPrice * discountRate / 100));
          }
          else
          {
            totalAmount = parseFloat((amount * unitPrice) - (amount * unitPrice * discountRate / 100));
            vatAmount = parseFloat(((amount * unitPrice) - (amount * unitPrice * discountRate / 100)) * (vatRate / 100));
          }
          var newDiscountAmount = parseFloat((amount * unitPrice) - ((amount * unitPrice) - (amount * unitPrice * discountRate / 100)));
          $(element).find(".n-format-item-discount-amount").val(newDiscountAmount.toFixed(2));
        
        }
        $(element).find(".n-format-item-vat-amount").val(vatAmount.toFixed(2));
        $(element).find(".n-format-item-total-amount").val(totalAmount.toFixed(2));
        _this.invoiceSummary();
      }

      _this.invoiceSummary = function() {

        var mainTotalAmount = 0;
        $('#itemContent tr.item-record').not('#newItemClone').each(function(index, el) {
          mainTotalAmount += parseFloat($(el).find('.n-format-item-amount').val().replace('.',"").replace(",",".")) * parseFloat($(el).find('.n-format-item-unit-price').val().replace('.',"").replace(",","."));
        });
        $(".n-format-total-amount").html(Numeral(mainTotalAmount).format('0,0.00'));
        $(".n-format-hidden-total-amount").val(mainTotalAmount);

        var totalAmount = _this.itemRowsCalculate(".n-format-item-total-amount");
        var totalUnitPrice = _this.itemRowsCalculate(".n-format-item-unit-price");

        

        var totalDiscound = _this.itemRowsCalculate(".n-format-item-discount-amount");
        $(".n-format-total-discount").html(Numeral(totalDiscound).format('0,0.00'));
        $(".n-format-hidden-total-discount").val(totalDiscound);

        var totalTotalVat = _this.itemRowsCalculate(".n-format-item-vat-amount");
        $(".n-format-total-vat").html(Numeral(totalTotalVat).format('0,0.00'));
        $(".n-format-hidden-total-vat").val(totalTotalVat);

        var totalDiscoundAmount = totalAmount;
        $(".n-format-total-discount-amount").html(Numeral(totalDiscoundAmount).format('0,0.00'));
        $(".n-format-hidden-total-discount-amount").val(totalDiscoundAmount);

        var totalVatAmount = totalAmount + totalTotalVat
        $(".n-format-total-vat-amount").html(Numeral(totalVatAmount).format('0,0.00'));
        $(".n-format-hidden-total-vat-amount").val(totalVatAmount);

        var amoundPaid = totalAmount + totalTotalVat;
        $(".n-format-amount-paid").html(Numeral(amoundPaid).format('0,0.00'));
        $(".n-format-hidden-amount-paid").val(amoundPaid);
      }

      _this.itemRowsCalculate = function(selector) {
        var calculate = 0;
        $('#itemContent ' + selector).each(function(index, el) {
          //console.log(el);
          calculate += parseFloat($(el).val().replace(".","").replace(",","."));
        });
        return calculate;
      }

      _this.customerChange = function (el, type) {
        $("#customerBtnType").find('button').removeClass("active");
        $(el).addClass("active");
        if(type == 1)
        {
          $("[data-customer=1]").show();
          $("[data-customer=2]").hide();

          $("#customerName").attr("required");
          $("#customerSurname").attr("required");
          $("#customerTCKN").attr("required");

          $("#firmName").removeAttr("required");
          $("#taxNo").removeAttr("required");
          $("#taxAdministration").removeAttr("required");
        }
        else
        {
          $("[data-customer=1]").hide();
          $("[data-customer=2]").show();

          $("#customerName").removeAttr("required");
          $("#customerSurname").removeAttr("required");
          $("#customerTCKN").removeAttr("required");

          $("#firmName").attr("required");
          $("#taxNo").attr("required");
          $("#taxAdministration").attr("required");

        }
        $("#customer_type").val(type);
      }

      _this.save = function () {

      }

      _this.itemListJsonCreate = function() {

        var itemList = [];

        $('#itemContent .item-record').not('#newItemClone').each(function(index, el) {

          //console.log(index, el);

          var item = {
            disableVatExemption: true, 
            inventoryCard: $(el).find('.service-name').val(), 
            amount:parseFloat($(el).find('.n-format-item-amount').val().replace(".","").replace(",",".")).toFixed(2), 
            discountAmount:$(el).find('.n-format-item-discount-amount').val(),
            lineAmount: $(el).find('.n-format-item-total-amount').val(),
            vatAmount: $(el).find('.n-format-item-vat-amount').val(),
            unitCode: $(el).find('.unit-code').val(),
            unitPrice: $(el).find('.n-format-item-unit-price').val(),
            vatRate: $(el).find('.n-format-item-vat-rate').val(),
            vatExemptionReasonCode: null,
          };

          itemList.push(item);
        });

        $(".n-format-hidden-invoices-lines").val(JSON.stringify(itemList));
        return itemList;
        //console.log(itemList);
        
      }
  
  })(Site);

  global.Site = $.extend(global.Site, Site);

}