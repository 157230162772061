export default function currencyFormat() {

    var Site = global.Site || {};

    (function(app) {
        'use strict';

        app.helpers.currencyFormat = {};

        var _this = app.helpers.currencyFormat;

        _this.format = function(price, symbol, currency, culture, minDigits) {
            var currency_symbol = symbol;
            var formattedOutput = new Intl.NumberFormat(culture, {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: minDigits !== null ? minDigits : 2,
            });
            return formattedOutput.format(price).replace(currency_symbol, "");
        }

    })(Site);

    global.Site = $.extend(global.Site, Site);

}