export default function component() {

    var Site = global.Site || {};

    (function(app) {
        'use strict';
    
        app.component = {};
    
        var _this = app.component;
    
        _this.init = function() {
    
    
        };
    
    })(Site);

    global.Site = $.extend(global.Site, Site);

}

